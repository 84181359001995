<template>
  <div class="font-mono w-screen h-screen flex">
    <div
      class="flex-1 bg-cover bg-center"
      :style="{
        backgroundImage: `url('https://images.pexels.com/photos/17024198/pexels-photo-17024198/free-photo-of-parts-of-a-pink-anemone-on-a-white-background.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')`,
      }"
    ></div>
    <div
      class="flex-1 flex justify-between items-center flex-col bg-gray-200 text-black pb-12"
    >
      <div class="flex flex-col justify-center items-center flex-1">
        <div
          class="text-5xl font-bold mb-4 flex flex-col justify-center items-center"
        >
          <span>Xavier</span>
          <span class="text-base text-gray-600 font-thin mt-1"
            >I have no idea</span
          >
        </div>
        <span class="text-sm font-thin mb-14 italic"
          >We had the stars, you and I, and this is given once only</span
        >
        <button
          class="rounded-full bg-black text-center text-sm px-5 py-3 text-white"
        >
          View more
        </button>
      </div>
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noopener noreferrer"
        >粤ICP备2021162436号-1</a
      >
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
