<template>
  <router-view />
</template>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
